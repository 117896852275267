import styled from "styled-components"
import breakpoints from "./breakpoints"
import { bold20, bold24, regular16, semibold16 } from "./typography"
import { baseGridStyles } from "./base"
const StyledNotification = styled.div`
    display: block;
    text-align: center;
    background: var(--light-green-darker);
    padding: 1.6rem;
    color: var(--white);
    ${regular16}
    font-weight: 700;
    a, a:link, a:visited, a:hover, a:active {
        color: var(--white);
        border-bottom: 2px dotted var(--white)
    }

`


const StyledWorkshopOffer = styled.div`

    
    grid-column: span 4;
    ${semibold16}
    padding: 1.6rem;
    margin-left: -1.6rem;
    margin-right: -1.6rem;
    margin-bottom: -1.6rem;
    background: var(--notification-blue);

    @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: span 6;
        ${baseGridStyles}
        align-items: center;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: span 12;
        margin-top: 1.6rem;    
        margin-left: 0rem;
        margin-right: 0rem;
        margin-bottom: 0;
    }
    
    
    p {
        
        color: var(--white);
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: 1 / 7;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 1 / 10;
            ${bold20}
        }

        strong {
            font-weight: 900;
            @media screen and (min-width: ${breakpoints.md}px) {
                ${bold24}
                display: block;
            }
        }

        span {
            display: inline;
            font-weight: 900;
            @media screen and (min-width: ${breakpoints.md}px) {
                // display: block;
            }
        }
    }

    button {
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: 5 / 7;
            font-weight: 900;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 10 / 12;
            
            
        }
    }
`

export {
    StyledNotification,
    StyledWorkshopOffer
}