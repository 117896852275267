import React from "react"
import { useShoppingCart } from 'use-shopping-cart'
import { StyledRegisterNowButton } from "../styledComponents/button"
import { StyledBlockQuote } from "../styledComponents/base"
import Icon from "../components/icon"

const ResetNervousSystemCopy = (data) => {
    const { checkoutSingleItem } = useShoppingCart()
    return (
        <>
            <p>You will be given the tools to <strong>rewire, regulate and reset</strong>. </p>

            <p>The key to regulating your nervous system is using these simple techniques to retrain yourself to bounce back quickly from stress.</p>

            <p>To make this realistic the techniques in this workshop are short, fast and effective. They can be used during your day and some take as little as 20 seconds.</p>

            <p>For example you may find yourself:</p>

            <ul>
                <li>Unable to get out of bed in the morning</li>
                <li>Tense after a stressful conversation that u can’t stop thinking about</li>
                <li>Feeling tired and lethargic</li>
                <li>Anxious before a meeting</li>
                <li>Drained after a meeting</li>
                <li>Ungrounded and overloaded with stress</li>
                <li>Unable to control how you respond to family members when they stress you out</li>
                <li>Anxious before a social event</li>
                <li>Anxious about current world events</li>
            </ul>

            <p>During the workshop you will learn how to follow specific quick processes to quickly <strong>retrain your nervous system back into a state of calm</strong>. </p>

            <h2>Your Daily Energy Routine</h2>

            <p>For example, you will learn fast techniques to use:</p>

            <ul>
                <li>When you wake up</li>
                <li>Before eating to help digestion</li>
                <li>Feel tired and need a fast energy boost</li>
                <li>After talking to a person that has stressed you out</li>
                <li>Before talking to a demanding person</li>
                <li>As you feel anxiety or panic setting in</li>
                <li>When you feel overloaded by screen time</li>
                <li>To quickly calm a mind that is overthinking</li>
                <li>Before you go to sleep</li>
                <li>Wake in the night and can’t get back to sleep</li>
            </ul>

            <p>And so much more…</p>

            <p>In addition you will also learn the core and deeper Permissioning and Transformation™ Hand to Heart techniques which are exceptionally powerful at:</p>

            <ul>
                <li>Releasing stuck emotions</li>
                <li>Releasing fears</li>
                <li>Gaining new perspectives on any situation</li>
                <li>Overcoming anxiety</li>
                <li>Overcoming depression</li>
                <li>Rewiring the nervous system after <abbr title="Post Traumatic Stress Disorder">PTSD</abbr></li>
                <li>Increasing resilience</li>
            </ul>

            <p>After any high stress situation it is essential to have access to tools and techniques that you can use frequently and easily to bring you back into a state of inner peace.</p>

            <p>Any trauma leaves a person feeling scared and unsafe. When your system understands at all levels that you are truly safe again it will stop the fight or flight symptoms and re-regulate again. </p>

            <p>All healing and emotional repair stems from feeling safe to let go and relax. Permissioning and Transformation™ is exceptional at doing this quickly.</p>

            <p>Karina has taught these techniques in various settings, including corporate, women’s groups and to many key workers during lockdown.</p>

            {/* <StyledRegisterNowButton
                onClick={() => {
                    checkoutSingleItem({ sku: data.sku })
                }
                }>Get Access for Just £{data.salePriceWithDecimal}</StyledRegisterNowButton> */}
        </>
    )
}

export default ResetNervousSystemCopy