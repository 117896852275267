import React from "react"
import { useShoppingCart, formatCurrencyString } from 'use-shopping-cart'
import {
  ProductFigureCaption,
  StyledProductSingleCard,
  StyledProductSingleFigure,
  StyledProductSingleHeader,
  StyledProductBookingButtons,
  StyledProductExcerptSingle
} from "./StyledPrimaryProductCard"
import {
  StyledLinkButton
} from "../../styledComponents/button"
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser"
import Icon from "../icon"


const SingleProductBook = props => {
  const { 
    title, 
    featuredImage, 
    uri, 
    excerpt, 
    amazonLink,
    fullPrice, 
    salePrice,
    content,
    reviewCount,
    ratingValue
  } = props
  const { addItem, checkoutSingleItem } = useShoppingCart()
  const image = {
    imageData: featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: featuredImage?.node?.alt || ``,
    description: featuredImage?.node?.description || "",
  }

  return (
    <StyledProductSingleCard
      itemScope
      itemType="http://schema.org/Product"
    >
      <meta itemProp="availability" content="https://schema.org/InStock" />
      <meta itemProp="priceCurrency" content="GBP" />
      
      {reviewCount != null &&
      <div itemProp="aggregateRating" itemType="https://schema.org/AggregateRating" itemScope>
        <meta itemProp="reviewCount" content={reviewCount} />
        <meta itemProp="ratingValue" content={ratingValue} />
      </div>
      }
      <span to={uri} itemProp="url">
        {image && image.imageData && (
          <StyledProductSingleFigure>
            <GatsbyImage
              image={image.imageData}
              alt={image.alt}
              width="100%"
              padding-bottom="0" />
            <ProductFigureCaption>{image.description}</ProductFigureCaption>
          </StyledProductSingleFigure>
        )}

          <StyledProductSingleHeader itemProp="name">
            {parse(title)}
          </StyledProductSingleHeader>
          <StyledProductExcerptSingle>{parse(excerpt)}</StyledProductExcerptSingle>
          <StyledProductBookingButtons>
            <StyledLinkButton target="_blank" href={props.amazonLink} className="amazon">Buy on Amazon</StyledLinkButton>
          </StyledProductBookingButtons>
          <>
          
          </>
        
      </span>
      <div className="product-description" itemProp='description'>
        {parse(content)}
      </div>
      
    </StyledProductSingleCard>
  )
}

export default SingleProductBook
