import React, { useState } from "react"
import styled from "styled-components"
import Sticky from 'react-sticky-el'
import { navigate } from "gatsby"
import { ModalProvider, BaseModalBackground } from "styled-react-modal"
import { useShoppingCart, formatCurrencyString } from 'use-shopping-cart'
import { AnchorLink } from "gatsby-plugin-anchor-links"
import {
  ProductFigureCaption,
  StyledProductSingleCard,
  StyledProductSingleFigure,
  StyledProductSingleHeader,
  StyledProductBookingButtons,
  StyledProductExcerptSingle,
  StyledShareSocialTools,
  StyledAsSeenIn,
  StyledAsSeenInLede
} from "./StyledPrimaryProductCard"
import {
  StyledLinkButton, 
  StyledRegisterNowButton
} from "../../styledComponents/button"
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser"
import Icon from "../../components/icon"

import lunchtimeReset from "../../fixtures/mindfulness-collection-lunchtime-reset"
import { StyledModal, StyledModalHeader } from "../../styledComponents/modal"
import { StyledButton, StyledCloseModalButton, StyledAnchorLink } from "../../styledComponents/button"
import KarinaGrantLogo from "../logo"
import AudioCard from "../../components/App/audioCard" 
import { StyledAudio } from "../../styledComponents/app/audioCard"
import AudioModal from "../../components/App/AudioModal"
import PermissioningSalesForm from "../Products/PermissioningSalesForm"

import SleepCopy from "../../fixtures/sleepWebPageShopCopy"
import ResetAnxiety from "../../fixtures/resetAnxietyWebPageShopCopy"
import ResetCopy from "../../fixtures/resetWebPageShopCopy"
import ResetNervousSystemCopy from "../../fixtures/resetNervousSystemWebPageShopCopy"
import ShareTools from "../socialLinks"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import SelfHealingMasteryFlashSale from "../Offer/SelfHealingMasteryFlashSale"

function FancyModalButton(data) {
  const [isOpen, setIsOpen] = useState(false);
  const [opacity, setOpacity] = useState(0);

  function toggleModal(e) {
    setOpacity(0);
    setIsOpen(!isOpen);
  }
  
  function closeModal(e) {
    setOpacity(0);
    setIsOpen(!isOpen);
  }

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  }

  function beforeClose() {
    return new Promise((resolve) => {
      setOpacity(0);
      setTimeout(resolve, 300);
    });
  }

  let collection

  if (data.id == 'cG9zdDo3Mzcz') collection = lunchtimeReset

  const image = {
    imageData: data.image,
    alt: ``,
    description: "",
  }



  return (
    <>
      <StyledButton className="listen-now" onClick={toggleModal}>Listen now</StyledButton>
      <StyledModal
        isOpen={isOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        opacity={opacity}
        backgroundProps={{ opacity }}
        // className="audio-list-modal"
        className={collection[0].type == 'shm-program' ? 'shm-program-modal' :
          collection[0].type == 'permission-reset-sleep' ? 'permission-reset-sleep-modal' : 'audio-list-modal'}
      >
        <StyledModalHeader type={collection[0].type}>
          <div>
            <KarinaGrantLogo />
            <StyledCloseModalButton className="close-audio" onClick={closeModal}>
              <Icon name="cross" />
            </StyledCloseModalButton>
          </div>
        </StyledModalHeader>
        <StyledAudio className={collection[0].type == 'single' && 'single-audio'}>
          {
            collection[0].type == 'single' ? <AudioModal audio={collection[0]} /> :
              collection[0].audioFiles.map(audio => {
                return (
                  <AudioCard
                    key={audio.title + `-1`}
                    title={audio.title}
                    image={audio.image}
                    color={audio.color}
                    audioMp3={audio.audioMp3}
                    duration={audio.duration}
                  />
                )
              })
          }
        </StyledAudio>
      </StyledModal>
    </>
  )
}

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: all 0.3s ease-in-out;
`;

const onEnterFunc = ({ }) => {
  // console.log('onEnterFunc', e)
}

const   SingleProduct = props => {
  const {
    title,
    featuredImage,
    uri,
    excerpt,
    fullPrice,
    salePrice,
    currency,
    testimonials,
    hidesale,
    content,
    id,
    stripePaymentLink,
    reviewCount,
    ratingValue
  } = props
  const { addItem, checkoutSingleItem } = useShoppingCart()
  const image = {
    imageData: featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: featuredImage?.node?.alt || ``,
    description: featuredImage?.node?.description || "",
  }

  const fullPriceFormatted = formatCurrencyString({ value: parseInt(fullPrice.edges[0].node.unit_amount), currency: fullPrice.edges[0].node.currency })
  const salePriceFormatted = formatCurrencyString({ value: parseInt(salePrice.edges[0].node.unit_amount), currency: salePrice.edges[0].node.currency })
  const re = /\b(\d+)(\d{2})\b/
  const fp = fullPrice.edges[0].node.unit_amount
  const sp = salePrice.edges[0].node.unit_amount
  const subst = '$1.$2'
  const fullPriceWithDecimal = fp.toString().replace(re, subst)
  const salePriceWithDecimal = sp.toString().replace(re, subst)

  let collection
  if (id == 'cG9zdDo3Mzcz') collection = lunchtimeReset
  const shareURL = `https://${process.env.GATSBY_DOMAIN_NAME}` + uri
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <>
      <StyledProductSingleCard
        itemScope
        itemType="http://schema.org/Product"
      >
        <meta itemProp="url" content={uri} />
        {reviewCount != null &&
          <div itemProp="aggregateRating" itemType="https://schema.org/AggregateRating" itemScope>
            <meta itemProp="reviewCount" content={reviewCount} />
            <meta itemProp="ratingValue" content={ratingValue} />
          </div>
        }
        <span>
          {image && image.imageData && (
            <StyledProductSingleFigure>
              <GatsbyImage
                image={image.imageData}
                alt={image.alt}
                width="100%"
                padding-bottom="0" />
              <ProductFigureCaption>{image.description}</ProductFigureCaption>
            </StyledProductSingleFigure>
          )}

          <div>
            <StyledProductSingleHeader itemProp="name">
              {parse(title)}
            </StyledProductSingleHeader>

            {id == 'cG9zdDo3Mzcz' &&
              <ModalProvider backgroundComponent={FadingBackground}>
                <FancyModalButton id="cG9zdDo3Mzcz" />
              </ModalProvider>
            }
            
            {fullPrice.edges[0].node.unit_amount !== 0 ?
              <>
                {props.sale && !props.hidesale || process.env.GATSBY_SHOW_SALE === 'true' ?
                  <div className="product-price product-price-single sale" itemProp="offers" itemScope itemType="https://schema.org/AggregateOffer">
                    <meta itemProp="availability" content="https://schema.org/InStock" />
                    <meta itemProp="priceCurrency" content={salePrice.edges[0].node.currency} />
                    <Icon name="sale" /> <span itemProp="highPrice" className="strikethrough">{currency}{fullPriceWithDecimal}</span> <span itemProp="lowPrice" className="saleprice">{currency}{salePriceWithDecimal}</span>
                  </div>
                  : props.tag.nodes[0].slug.includes('qt-product') ?
                  <div className="product-price product-price-single">
                    <meta itemProp="priceCurrency" content={fullPrice.edges[0].node.currency} />
                    <span itemProp="price" content={fullPriceWithDecimal}>{fullPriceFormatted}</span>
                  </div>
                  :
                  <div className="product-price product-price-single">
                    <meta itemProp="priceCurrency" content={fullPrice.edges[0].node.currency} />
                    <span itemProp="price" content={fullPriceWithDecimal}>{fullPriceFormatted}</span>
                  </div>
                }
                <StyledProductExcerptSingle>{parse(excerpt)}</StyledProductExcerptSingle>

                <StyledProductBookingButtons>
                  {props.sale && !props.hidesale ?
                    props.tag.nodes[0].slug.includes('permission') ?
                      <span>
                        <StyledRegisterNowButton
                          onClick={() => {
                            navigate(`${stripePaymentLink}`)
                          }
                          }>Buy now {currency}{salePriceWithDecimal}</StyledRegisterNowButton>
                        <span className="or">or take advantage of this amazing offer</span>
                        {title.includes('Sleep') ?
                          <AnchorLink className="anchor-offer-link" to={`${uri}#permissioning-offer`}>Access Permission to Reset™ Sleep AND Permission to Reset™ Anxiety<br /> (save &pound;95.00) only &pound;47.77</AnchorLink>
                          :
                          <AnchorLink className="anchor-offer-link" to={`${uri}#permissioning-offer`}>Access Permission to Reset™ Anxiety AND Permission to Reset™ Sleep<br /> (save &pound;95.00) only &pound;47.77</AnchorLink>
                        }

                      </span>
                      :
                      <>
                        <StyledRegisterNowButton
                          onClick={() => {
                            navigate(`${stripePaymentLink}`)
                          }
                          }>Buy now</StyledRegisterNowButton>
                      </>
                    : props.tag.nodes[0].slug.includes('permission') ?
                      <span>
                        <StyledRegisterNowButton
                          onClick={() => {
                            navigate(`${stripePaymentLink}`)
                          }
                          }>Buy now</StyledRegisterNowButton>
                      </span>
                      : props.tag.nodes[0].slug.includes('qt-product') ?
                        <span>
                          <StyledLinkButton
                            className="external"
                            target="_blank"
                            href={stripePaymentLink}
                            onClick={() => {
                              navigate(`${stripePaymentLink}`)
                            }}
                          >
                            Buy now <Icon name="external" /></StyledLinkButton>
                        </span>
                        :
                        <>
                          <StyledRegisterNowButton
                            onClick={() => {
                              navigate(`${stripePaymentLink}`)
                            }
                            }>Buy now</StyledRegisterNowButton>
                        </>
                  }

                  {/* <StyledNotice>All audios, videos and content found on Karina Grant's website, is exclusively copyrighted by KG and/or its affiliates. No content may be copied, reproduced, republished, downloaded, posted or transmitted in any way. You may not “mirror” any content contained on this Site without express written consent from Karina Grant.</StyledNotice> */}
                </StyledProductBookingButtons>

                {props.tag.nodes[0].slug.includes('permission') && props.id === "cG9zdDo3MjM0" &&
                  <StyledAsSeenIn>
                    <StyledAsSeenInLede>As seen in </StyledAsSeenInLede>
                    <img src="/images/corporate/situlive-westfield.png?v1" alt="Situ Live &amp; Wesfield Logo" />
                  </StyledAsSeenIn>
                }




              </>
              : props.tag.nodes[0].slug.includes('resetnervoussystem') ?
                <>
                  <StyledProductExcerptSingle>{parse(excerpt)}</StyledProductExcerptSingle>
                  <span>
                    <StyledRegisterNowButton
                      onClick={() => {
                        navigate(`${stripePaymentLink}`)
                      }
                      }>Buy now {currency}{salePriceWithDecimal}
                    </StyledRegisterNowButton>
                  </span>
                </>
                :
                null
            }

            <div itemProp="brand" itemType="https://schema.org/Brand" itemScope>
              <meta itemProp="name" content="Karina Grant" />
            </div>
          </div>
        </span>

        {testimonials?.blockquote != undefined ?
          <div className="tabslist">
            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)} >
              <Sticky boundaryElement=".tabslist" hideOnBoundaryHit={true}>
                <TabList className='react-tabs__tab-list product-list'>
                  <Tab>Overview</Tab>
                  <Tab>Testimonials</Tab>
                </TabList>
              </Sticky>
              <TabPanel>
                <div className="product-description" itemProp='description'>
                  {props.tag.nodes[0].slug.includes('permission') ?
                    <>
                      {props.id === "cG9zdDo3MjM0" ?
                        <ResetAnxiety title={title} fullPriceWithDecimal={fullPriceWithDecimal} salePriceWithDecimal={salePriceWithDecimal} sku={fullPrice.edges[0].node.id} />
                        : props.id === "cG9zdDo3ODE2" ?
                          <ResetCopy title={title} fullPriceWithDecimal={fullPriceWithDecimal} salePriceWithDecimal={salePriceWithDecimal} sku={fullPrice.edges[0].node.id} />
                          :
                          <SleepCopy title={title} fullPriceWithDecimal={fullPriceWithDecimal} salePriceWithDecimal={salePriceWithDecimal} sku={fullPrice.edges[0].node.id} />
                      }


                      <StyledProductBookingButtons position={'bottom'}>
                        <StyledProductSingleHeader itemProp="name">
                          {parse(title)}
                        </StyledProductSingleHeader>
                        {props.sale && !props.hidesale || process.env.GATSBY_SHOW_SALE === 'true' ?
                          <div className="product-price product-price-single sale" itemProp="offers" itemScope itemType="https://schema.org/AggregateOffer">
                            <meta itemProp="availability" content="https://schema.org/InStock" />
                            <meta itemProp="priceCurrency" content={salePrice.edges[0].node.currency} />
                            <Icon name="sale" /> <span itemProp="highPrice" className="strikethrough">{fullPriceFormatted}</span> <span itemProp="lowPrice" className="saleprice">{currency}{props.sale}</span>
                          </div>
                          :
                          <div className="product-price product-price-single">
                            <meta itemProp="priceCurrency" content={fullPrice.edges[0].node.currency} />
                            <span itemProp="price" content={fullPriceWithDecimal}>{fullPriceFormatted}</span>
                          </div>
                        }

                        {props.sale && !props.hidesale ?
                          props.tag.nodes[0].slug.includes('permission') || props.tag.nodes[0].slug.includes('quantum-touch') || props.tag.nodes[0].slug.includes('collection') || props.tag.nodes[1].slug.includes('black-friday-offer') ?
                            <span>
                              <StyledRegisterNowButton
                                onClick={() => {
                                  navigate(`${stripePaymentLink}`)
                                }
                                }>Buy now {currency}{salePriceWithDecimal}
                              </StyledRegisterNowButton>
                              <span className="or">or take advantage of this amazing offer</span>
                              {title.includes('Sleep') ?
                                <AnchorLink className="anchor-offer-link" to={`${uri}#permissioning-offer`}>Access Permission to Reset™ Sleep AND Permission to Reset™ Anxiety<br /> (save &pound;95.00) only &pound;47.77</AnchorLink>
                                :
                                <AnchorLink className="anchor-offer-link" to={`${uri}#permissioning-offer`}>Access Permission to Reset™ Anxiety AND Permission to Reset™ Sleep<br /> (save &pound;95.00) only &pound;47.77</AnchorLink>
                              }
                            </span>
                            :
                            <>
                              <StyledRegisterNowButton
                                onClick={() => {
                                  navigate(`${stripePaymentLink}`)
                                }
                                }>Buy now
                              </StyledRegisterNowButton>
                            </>
                          : props.tag.nodes[0].slug.includes('permission') ?
                            <span>
                              <StyledRegisterNowButton
                                onClick={() => {
                                  navigate(`${stripePaymentLink}`)
                                }
                                }>Buy now</StyledRegisterNowButton>
                            </span>
                            :
                            <>
                              <StyledRegisterNowButton
                                onClick={() => {
                                  navigate(`${stripePaymentLink}`)
                                }
                                }>Buy now</StyledRegisterNowButton>
                            </>
                        }

                        {/* <StyledNotice>All audios, videos and content found on Karina Grant's website, is exclusively copyrighted by KG and/or its affiliates. No content may be copied, reproduced, republished, downloaded, posted or transmitted in any way. You may not “mirror” any content contained on this Site without express written consent from Karina Grant.</StyledNotice> */}
                      </StyledProductBookingButtons>
                    </>
                    : props.tag.nodes[0].slug.includes('collection') ||
                      props.tag.nodes[0].slug.includes('black-friday-offer') ||
                      props.tag.nodes[0].slug.includes('spoontherapy') ?
                      <>
                        {parse(content)}


                        <StyledProductBookingButtons position={'bottom'}>
                          {props.sale && !props.hidesale || process.env.GATSBY_SHOW_SALE === 'true' ?
                            <div className="product-price product-price-single sale" itemProp="offers" itemScope itemType="https://schema.org/AggregateOffer">
                              <meta itemProp="availability" content="https://schema.org/InStock" />
                              <meta itemProp="priceCurrency" content={salePrice.edges[0].node.currency} />
                              <Icon name="sale" /> <span itemProp="highPrice" className="strikethrough">{fullPriceFormatted}</span> <span itemProp="lowPrice" className="saleprice">{currency}{props.sale}</span>
                            </div>
                            :
                            <div className="product-price product-price-single">
                              <meta itemProp="priceCurrency" content={fullPrice.edges[0].node.currency} />
                              <span itemProp="price" content={fullPriceWithDecimal}>{fullPriceFormatted}</span>
                            </div>
                          }
                          <StyledRegisterNowButton
                            onClick={() => {
                              navigate(`${stripePaymentLink}`)
                            }
                            }>Buy now</StyledRegisterNowButton></StyledProductBookingButtons>
                      </>
                      :
                      <>
                        {parse(content)}
                        <StyledProductBookingButtons position={'bottom'}>

                          <StyledRegisterNowButton
                            onClick={() => {
                              navigate(`${stripePaymentLink}`)
                            }
                            }>Buy now</StyledRegisterNowButton></StyledProductBookingButtons>
                      </>
                  }
                </div>
              </TabPanel>
              <TabPanel>
                <ul className="testimonials">
                  {testimonials?.blockquote?.map((quotes, index) => {
                    return (
                      <li>
                        <blockquote>{parse(quotes.quote)}
                          <cite>
                            <b>{quotes.citationName}</b>, {quotes.citeLocation}
                          </cite>
                        </blockquote>
                      </li>
                    )
                  })}
                </ul>
              </TabPanel>
            </Tabs>
          </div>
          :

          <div className="product-description" itemProp='description'>
            {props.tag.nodes[0].slug.includes('permission') ?
              <>
                {props.id === "cG9zdDo3MjM0" ?
                  <ResetAnxiety title={title} fullPriceWithDecimal={fullPriceWithDecimal} salePriceWithDecimal={salePriceWithDecimal} sku={fullPrice.edges[0].node.id} />
                  : props.id === "cG9zdDo3ODE2" ?
                    <ResetCopy title={title} fullPriceWithDecimal={fullPriceWithDecimal} salePriceWithDecimal={salePriceWithDecimal} sku={fullPrice.edges[0].node.id} />
                    : props.id === 'cG9zdDo5MDkz' ?
                    <ResetNervousSystemCopy title={title} fullPriceWithDecimal={fullPriceWithDecimal} salePriceWithDecimal={salePriceWithDecimal} sku={fullPrice.edges[0].node.id} />
                    :
                    <SleepCopy title={title} fullPriceWithDecimal={fullPriceWithDecimal} salePriceWithDecimal={salePriceWithDecimal} sku={fullPrice.edges[0].node.id} />
                }


                <StyledProductBookingButtons position={'bottom'}>
                  <StyledProductSingleHeader itemProp="name">
                    {parse(title)}
                  </StyledProductSingleHeader>
                  {props.sale && !props.hidesale || process.env.GATSBY_SHOW_SALE === 'true' ?
                    <div className="product-price product-price-single sale" itemProp="offers" itemScope itemType="https://schema.org/AggregateOffer">
                      <meta itemProp="priceCurrency" content={salePrice.edges[0].node.currency} />
                      <Icon name="sale" /> <span itemProp="highPrice" className="strikethrough">{fullPriceFormatted}</span> <span itemProp="lowPrice" className="saleprice">{currency}{props.sale}</span>
                    </div>
                    :
                    <div className="product-price product-price-single">
                      <meta itemProp="priceCurrency" content={fullPrice.edges[0].node.currency} />
                      <span itemProp="price" content={fullPriceWithDecimal}>{fullPriceFormatted}</span>
                    </div>
                  }

                  {props.sale && !props.hidesale ?
                    props.tag.nodes[0].slug.includes('permission') || props.tag.nodes[0].slug.includes('quantum-touch') || props.tag.nodes[0].slug.includes('collection') || props.tag.nodes[1].slug.includes('black-friday-offer') ?
                      <span>
                        <StyledRegisterNowButton
                          onClick={() => {
                            navigate(`${stripePaymentLink}`)
                          }
                          }>Buy now {currency}{salePriceWithDecimal}
                        </StyledRegisterNowButton>
                        <span className="or">or take advantage of this amazing offer</span>
                        {title.includes('Sleep') ?
                          <AnchorLink className="anchor-offer-link" to={`${uri}#permissioning-offer`}>Access Permission to Reset™ Sleep AND Permission to Reset™ Anxiety<br /> (save &pound;95.00) only &pound;47.77</AnchorLink>
                          :
                          <AnchorLink className="anchor-offer-link" to={`${uri}#permissioning-offer`}>Access Permission to Reset™ Anxiety AND Permission to Reset™ Sleep<br /> (save &pound;95.00) only &pound;47.77</AnchorLink>
                        }
                      </span>
                      :
                      <>
                        <StyledRegisterNowButton
                          onClick={() => {
                            navigate(`${stripePaymentLink}`)
                          }
                          }>Buy now
                        </StyledRegisterNowButton>
                      </>
                    : props.tag.nodes[0].slug.includes('permission') ?
                      <span>
                        <StyledRegisterNowButton
                          onClick={() => {
                            navigate(`${stripePaymentLink}`)
                          }
                          }>Buy now</StyledRegisterNowButton>
                      </span>
                      :
                      <>
                        <StyledRegisterNowButton
                          onClick={() => {
                            navigate(`${stripePaymentLink}`)
                          }
                          }>Buy now</StyledRegisterNowButton>
                      </>
                  }

                  {/* <StyledNotice>All audios, videos and content found on Karina Grant's website, is exclusively copyrighted by KG and/or its affiliates. No content may be copied, reproduced, republished, downloaded, posted or transmitted in any way. You may not “mirror” any content contained on this Site without express written consent from Karina Grant.</StyledNotice> */}
                </StyledProductBookingButtons>
              </>
              : props.tag.nodes[0].slug.includes('collection') ||
                props.tag.nodes[0].slug.includes('black-friday-offer') ||
                props.tag.nodes[0].slug.includes('spoontherapy') ?
                <>
                  {parse(content)}

                  <StyledProductBookingButtons position={'bottom'}>
                    {props.sale && !props.hidesale || process.env.GATSBY_SHOW_SALE === 'true' ?
                      <div className="product-price product-price-single sale" itemProp="offers" itemScope itemType="https://schema.org/AggregateOffer">
                        <meta itemProp="availability" content="https://schema.org/InStock" />
                        <meta itemProp="priceCurrency" content={salePrice.edges[0].node.currency} />
                        <Icon name="sale" /> <span itemProp="highPrice" className="strikethrough">{fullPriceFormatted}</span> <span itemProp="lowPrice" className="saleprice">{currency}{props.sale}</span>
                      </div>
                      :
                      <div className="product-price product-price-single">
                        <meta itemProp="priceCurrency" content={fullPrice.edges[0].node.currency} />
                        <span itemProp="price" content={fullPriceWithDecimal}>{fullPriceFormatted}</span>
                      </div>
                    }
                    <StyledRegisterNowButton
                      onClick={() => {
                        navigate(`${stripePaymentLink}`)
                      }
                      }>Buy now</StyledRegisterNowButton></StyledProductBookingButtons>
                </>
                :
                parse(content)
            }
            <StyledShareSocialTools>
              <ShareTools shareTitle="Share this on" pageLocation={shareURL} pageTitle={title} />
            </StyledShareSocialTools>
          </div>
        }



      </StyledProductSingleCard>
    </>
  )
}

export default SingleProduct
